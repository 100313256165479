export const servicechannelid = 'UC_8_w-pM9yJXpqjMJguouLw';

export const tvchannelid = 'UCH2SnbAfs_5GEuuItw3vP-w';

export const apiKey = 'AIzaSyCigpYGgD6oSf4PfKLXn1f_kT7ADo01ak0';

export const liveserviceurl = 'https://www.googleapis.com/youtube/v3/search?key=' 
+ apiKey + '&channelId=' + servicechannelid + '&part=snippet,id&maxResults=1&type=video&eventType=live';


//export const serverurl = "http://localhost/projects/kcccbackend";

export const clienturl = "https://kccconline.org";

export const serverurl = "https://adeajalaministries.org/kcccbackend";

//export const clienturl = "https://kccconline.org";



